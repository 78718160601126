<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="결재요청 일자"
            name="period"
            v-model="period"
          />
        </div>
        {{  }}
      </template>
    </c-search-box>
    <q-table
      grid
      title=""
      :data="grid.data"
      :columns="grid.columns"
      hide-header
      hide-bottom
      :rows-per-page-options="[0]"
      virtual-scroll
    >
      <template v-slot:item="props">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
            <q-card-section horizontal class="bg-grey-2">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">[{{ props.row.approvalTypeName }}] {{ props.row.approvalRequestName }}</div>
              </q-card-section>
            </q-card-section>
            <q-card-section class="grid-card-etc">
              <div class="text-grid-etc-title">
                결재상태 :
              </div>
              <div :class="['text-grid-etc', getColorStatus(props.row.approvalStatusCd)]">
                {{ props.row.approvalStatusName }}
              </div>
            </q-card-section>
          </q-card>
        </div>
      </template>
    </q-table>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
      <q-card class="mobileTableCardLayer">
        <q-card-section horizontal class="bg-grey-2 text-center">
          <q-card-section class="q-pt-xs">
            <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
          </q-card-section>
        </q-card-section>
      </q-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'appr-todo-list',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        approvalDocType: '', // 결재함 유형
      }),
    },
  },
  data() {
    return {
      title: '',
      grid: {
        columns: [
          {
            name: 'approvalTypeName',
            field: 'approvalTypeName',
            label: '결재 업무명',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
          {
            name: 'approvalRequestName',
            field: 'approvalRequestName',
            label: '결재 요청명',
            align: 'left',
            sortable: true,
            type: 'link',
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재상태',
            align: 'center',
            sortable: true,
            style: 'width:70px',
          },
        ],
        data: [],
      },
      searchParam: {
        startYmd: '',
        endYmd: '',
        approvalUserId: '',
        approvalKindCd: '',
        approvalStatusCd: 'ASC0000001', // 결재할문서
      },
      period: ['', ''],
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
        isApproval: true,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  methods: {
    init() {
      this.listUrl = selectConfig.sys.appr.list.todo.url;
      this.getList();
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'ASC0000001': // 결재중
          cls = 'text-positive';
          break;
        case 'ASC0000002': // 반려
          cls = 'text-red';
          break;
        case 'ASC9999999': // 결재완료
          cls = 'text-orange-10';
          break;
        case 'ASC0000000': // 결재요청
          cls = 'text-primary';
          break;
      }
      return cls;
    },
    getList() {
      if (this.popupParam.approvalDocType) {
        this.getApprovalStatusCd();
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
        this.searchParam.approvalUserId = this.$store.getters.user.userId;
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    linkClick(ect, row) {
      this.popupOptions.title = '결재 상세'; // 안전관찰 상세
      this.popupOptions.param = {
        sysApprovalRequestId: row.sysApprovalRequestId,
        approvalDocType: this.popupParam.approvalDocType,
      };
      this.popupOptions.target = () => import(`${'./apprProcess.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getApprovalStatusCd() {
      switch(this.popupParam.approvalDocType) {
        case 'TODO': // 결재할 문서
          this.searchParam.approvalStatusCd = 'ASC0000001';
          this.searchParam.approvalKindCd = '';
          this.title = '결재할 문서 목록';
          break;
        case 'REQ': // 결재요청 문서
          this.searchParam.approvalStatusCd = '';
          this.searchParam.approvalKindCd = 'AKC0000001';
          this.title = '결재요청 문서 목록';
          break;
        case 'APPR': // 결재한 문서
          this.searchParam.approvalStatusCd = 'ASC9999999';
          this.searchParam.approvalKindCd = '';
          this.title = '결재한 문서 목록';
          break;
        case 'PASS': // 회람함
          this.searchParam.approvalStatusCd = '';
          this.searchParam.approvalKindCd = 'AKC0000009';
          this.title = '회람함 목록';
          break;
      }
    }
  }
};
</script>
