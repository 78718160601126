var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    range: true,
                    label: "결재요청 일자",
                    name: "period",
                  },
                  model: {
                    value: _vm.period,
                    callback: function ($$v) {
                      _vm.period = $$v
                    },
                    expression: "period",
                  },
                }),
              ],
              1
            ),
            _vm._v(" " + _vm._s() + " "),
          ]),
        ],
        2
      ),
      _c("q-table", {
        attrs: {
          grid: "",
          title: "",
          data: _vm.grid.data,
          columns: _vm.grid.columns,
          "hide-header": "",
          "hide-bottom": "",
          "rows-per-page-options": [0],
          "virtual-scroll": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (props) {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3",
                  },
                  [
                    _c(
                      "q-card",
                      {
                        staticClass: "mobileTableCardLayer",
                        on: {
                          click: function ($event) {
                            return _vm.linkClick(null, props.row)
                          },
                        },
                      },
                      [
                        _c(
                          "q-card-section",
                          {
                            staticClass: "bg-grey-2",
                            attrs: { horizontal: "" },
                          },
                          [
                            _c("q-card-section", { staticClass: "q-pt-xs" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-grid-title q-mt-sm q-mb-xs",
                                },
                                [
                                  _vm._v(
                                    "[" +
                                      _vm._s(props.row.approvalTypeName) +
                                      "] " +
                                      _vm._s(props.row.approvalRequestName)
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c("q-card-section", { staticClass: "grid-card-etc" }, [
                          _c("div", { staticClass: "text-grid-etc-title" }, [
                            _vm._v(" 결재상태 : "),
                          ]),
                          _c(
                            "div",
                            {
                              class: [
                                "text-grid-etc",
                                _vm.getColorStatus(props.row.approvalStatusCd),
                              ],
                            },
                            [
                              _vm._v(
                                " " + _vm._s(props.row.approvalStatusName) + " "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm.grid.data.length === 0
        ? _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" },
            [
              _c(
                "q-card",
                { staticClass: "mobileTableCardLayer" },
                [
                  _c(
                    "q-card-section",
                    {
                      staticClass: "bg-grey-2 text-center",
                      attrs: { horizontal: "" },
                    },
                    [
                      _c("q-card-section", { staticClass: "q-pt-xs" }, [
                        _c(
                          "div",
                          { staticClass: "text-grid-title q-mt-sm q-mb-xs" },
                          [_vm._v("데이터가 존재하지 않습니다.")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }